import { Box, Container, CSSObject, Grid, Stack } from "@mui/material"
import SmallBannerCard from "~/components/banners/SmallBannerCard"
import BigBannerCard from "~/components/carousel-cards/BigBannerCard"
import Carousel from "~/components/carousel/Carousel"
import { H1, H4, Paragraph } from "~/components/Typography"
import { FC, useEffect, useState } from "react"
import { SxProps } from "@mui/material/styles"
import BazaarImage from "~/components/BazaarImage"

// ======================================================
type Props = { main: any[]; small1: any[]; small2: any[] }
// ======================================================

const MainBanner: FC<Props> = ({ main, small1, small2 }: Props) => {
  // custom css
  const carouselStyles: CSSObject = {
    overflow: "hidden",
    borderRadius: "3px",
    "& .carousel__dot-group": {
      mt: 0,
      left: 0,
      right: 0,
      bottom: 10,
      position: "absolute",
      "& div": {
        borderColor: "dark.main",
        "::after": { backgroundColor: "dark.main" },
      },
    },
  }

  // CAROUSEL TEXT CONTENTS
  const carouselContent1 = (
    <>
      <Box pl={{ md: 10, cursor: "pointer" }}>
        <H4
          lineHeight={1}
          fontWeight={400}
          textTransform="uppercase"
          fontSize={{ sm: 30, xs: 24 }}
          mb={1}
        ></H4>

        <H1
          fontSize={{ sm: 60, xs: 44 }}
          lineHeight={1}
          textTransform="uppercase"
        ></H1>

        <H4
          fontSize={{ sm: 30, xs: 24 }}
          lineHeight={1}
          mt={1.5}
          textTransform="uppercase"
        ></H4>

        <Paragraph fontSize={{ sm: 18, xs: 14 }} mb={28}></Paragraph>
        {/* <Button variant="contained" size="large" sx={{background: "rgb(255,255,255,0.4)", color: "black"}}>
        Зочлох
      </Button> */}
      </Box>
    </>
  )

  const [windowWidth, setWindowWidth] = useState(false)
  useEffect(() => {
    window.innerWidth <= 1024 ? setWindowWidth(true) : setWindowWidth(false)
  })

  // interface BigBannerCardProps {
  //   content: any;
  //   mode: 'dark' | 'light';
  //   bgImage: string;
  //   url: string;
  //   sx?: SxProps; // MUI sx props төрлийг ашиглана
  // }

  // const BigBannerCard: React.FC<BigBannerCardProps> = ({ content, mode, bgImage, url, sx }) => {
  //   return (
  //     <Box
  //       component="a"
  //       href={url}
  //       sx={{
  //         display: 'block',
  //         backgroundImage: `url(${bgImage})`,
  //         backgroundSize: 'cover',
  //         backgroundPosition: 'center',
  //         height: '500px',
  //         position: 'relative',
  //         ...sx,
  //       }}
  //     >
  //     </Box>
  //   );
  // };

  return (
    <Box sx={{ mb: { xs: 1, md: 4 }, mt: 2 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={9} xs={12} sx={{ position: "relative" }}>
            <Carousel
              spacing="0px"
              totalSlides={main.length}
              infinite={true}
              interval={6000}
              showDots={true}
              dotColor={"#FFFFFF"}
              autoPlay={true}
              visibleSlides={1}
              showArrow={false}
              sx={carouselStyles}
            >
              {main.map((banner, index) => (
                <BigBannerCard
                  key={index}
                  content={carouselContent1}
                  mode="dark"
                  bgImage={banner.photoUrl}
                  url={banner.link}
                />
              ))}
            </Carousel>
          </Grid>
          {!windowWidth && small1.length > 0 && small2.length > 0 && (
            <Grid item md={3} xs={12} sx={{ opacity: { xs: 0, md: 1 } }}>
              <Stack
                height="100%"
                direction={{ md: "column", sm: "row", xs: "column" }}
                spacing={2}
              >
                <Carousel
                  spacing="0px"
                  totalSlides={small1.length}
                  infinite={true}
                  interval={6000}
                  showDots={true}
                  dotColor={"#FFFFFF"}
                  autoPlay={true}
                  visibleSlides={1}
                  showArrow={false}
                  sx={carouselStyles}
                >
                  {small1.map((banner, index) => (
                    <SmallBannerCard
                      key={index}
                      flex={1}
                      img={banner.photoUrl}
                      url={banner.link}
                    />
                  ))}
                </Carousel>
                <Carousel
                  spacing="0px"
                  totalSlides={small2.length}
                  infinite={true}
                  interval={6000}
                  showDots={true}
                  dotColor={"#FFFFFF"}
                  autoPlay={true}
                  visibleSlides={1}
                  showArrow={false}
                  sx={carouselStyles}
                >
                  {small2.map((banner, index) => (
                    <SmallBannerCard
                      key={index}
                      flex={1}
                      img={banner.photoUrl}
                      url={banner.link}
                    />
                  ))}
                </Carousel>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default MainBanner
