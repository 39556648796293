import { Alert, Box, Container, TextField, styled } from "@mui/material";
import BazaarButton from "~/components/BazaarButton";
import { FlexRowCenter } from "~/components/flex-box";
import Telegram from "~/components/icons/Telegram";
import { H3, H5 } from "~/components/Typography";
import { ActionFunction } from "@remix-run/node";
import { useActionData, useFetcher } from "@remix-run/react";
import { useEffect, useState } from "react";

const ContentWrapper = styled(Box)(({ theme }) => ({}));

function Newsletter() {
  const fetcher = useFetcher();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    if (
    fetcher.state === "idle" &&
    fetcher.data != null &&
    fetcher.data?.success)
    {
      setSuccess("Амжилттай бүртгэгдлээ!");
      setError("");
    } else if (
    fetcher.state === "idle" &&
    fetcher.data != null &&
    !fetcher.data?.success)
    {
      setError("Бүртгэлтэй и-мэйл хаяг байна!");
      setSuccess("");
    }
  }, [fetcher]);
  return (
    <Container sx={{ pb: "2rem", pt: { xs: "1rem", md: "2rem" } }}>
      <FlexRowCenter flexDirection="column">
        <Telegram sx={{ fontSize: "2.5rem", color: "grey.700" }} />
        <H3
          mt="1.5rem"
          mb="0.5rem"
          lineHeight={1.2}
          fontSize="24px"
          sx={{ textAlign: { xs: "center" } }}>

          Мэдээлэл авах
        </H3>
        <H5
        // fontWeight="600"
        // color="grey.600"
        mb="1.5rem"
        maxWidth="550px"
        textAlign="center"
        fontWeight="500"
        sx={{ fontSize: { md: "16px", xs: "14px" } }}>

          Бүтээгдэхүүний хямдрал, урамшууллын мэдээллийг цаг алдалгүй хүлээн
          авахыг хүсвэл и-мэйл хаягаа бүртгүүлээрэй.
        </H5>
        <fetcher.Form
          method="post"
          action="/subscription"
          id="subscribe-to-newsletter-form">

          <TextField
            variant="outlined"
            placeholder="И-мэйл хаягаа оруулна уу..."
            name="newsletterEmail"
            fullWidth
            InputProps={{
              sx: {
                height: 44,
                fontWeight: "200",
                mx: "auto",
                my: 2,
                borderRadius: "10px",
                paddingRight: 0,
                color: "secondary.600",
                overflow: "hidden",
                backgroundColor: "grey.300",
                "& .css-1o2jng6-MuiOutlinedInput-notchedOutline": {
                  borderWidth: 0
                }
              },
              endAdornment:
              <BazaarButton
                color="primary"
                variant="contained"
                disableElevation
                sx={{
                  px: "2rem",
                  height: "100%",
                  borderRadius: "0 8px 8px 0",
                  fontWeight: "600"
                }}
                type="submit">

                  Бүртгүүлэх
                </BazaarButton>

            }} />

        </fetcher.Form>

        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
      </FlexRowCenter>
    </Container>);

}

export default Newsletter;