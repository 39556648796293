import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material"
import ArrowRight from "@mui/icons-material/ArrowRight"
import {
  Badge,
  Box,
  Container,
  MenuItem,
  styled,
  type SvgIconProps,
} from "@mui/material"
import BazaarButton from "~/components/BazaarButton"
import BazaarCard from "~/components/BazaarCard"
import CategoryMenu from "~/components/categories/CategoryMenu"
import { FlexBox } from "~/components/flex-box"
import appIcons from "~/components/icons"
import NavLink from "~/components/nav-link/NavLink"
import { Paragraph, Span } from "~/components/Typography"
import navbarNavigations from "~/data/navbarNavigations"
import AuctionNavbarNavigation from "~/data/auctionNavbarNavigation"
import type { FC } from "react"
import MegaMenu from "./MegaMenu"
import MegaMenu2 from "./MegaMenu2"
import { Link, useLocation } from "@remix-run/react"
import BazaarImage from "../BazaarImage"

// NavList props interface
type Navs = {
  url: string
  title: string
  Icon?: (props: SvgIconProps<"svg", {}>) => JSX.Element
}

type NavList = {
  url: string
  title: string
  child: Navs[]
  megaMenu: boolean
  megaMenuWithSub: boolean
}

// const common css style
const navLinkStyle = {
  cursor: "pointer",
  transition: "color 150ms ease-in-out",
  "&:hover": { color: "primary.main" },
  "&:last-child": { marginRight: 0 },
}
// style components
const StyledNavLink = styled(NavLink)(() => ({ ...navLinkStyle }))

const ParentNav = styled(Box)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
    "& > .parent-nav-item": { display: "block" },
  },
}))

const StyledSpan = styled(Span)(() => ({
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}))
const ParentNavItem = styled(Box)(({ theme }) => ({
  top: 0,
  zIndex: 5,
  left: "100%",
  paddingLeft: 8,
  display: "none",
  position: "absolute",
  [theme.breakpoints.down(1640)]: {
    right: "100%",
    left: "auto",
    paddingRight: 8,
  },
}))

const NavBarWrapper = styled(BazaarCard)<{ border: number }>(
  ({ theme, border }) => ({
    height: "60px",
    display: "block",
    borderRadius: "0px",
    position: "relative",
    ...(border && { borderBottom: `1px solid ${theme.palette.grey[200]}` }),
    [theme.breakpoints.down(1025)]: { display: "none" },
  })
)

const InnerContainer = styled(Container)(() => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

const CategoryMenuButton = styled(BazaarButton)(({ theme }) => ({
  width: "278px",
  height: "40px",
  backgroundColor: theme.palette.grey[100],
}))

const ChildNavsWrapper = styled(Box)(() => ({
  zIndex: 5,
  left: "50%",
  top: "100%",
  display: "none",
  position: "absolute",
  transform: "translate(-50%, 0%)",
}))

// ==========================================================
type NavbarProps = {
  border: number
  elevation?: number
  navListOpen?: boolean
  hideCategories?: boolean
}
// ==========================================================

const Navbar: FC<NavbarProps> = ({
  navListOpen,
  hideCategories,
  elevation,
  border,
}) => {
  const renderNestedNav = (list: any[] = [], isRoot = false, url) => {
    return list.map((nav: NavList, index) => {
      if (isRoot) {
        const isActive = url == nav.url
        const key = `${nav.title}-${index}` // Construct a unique key using the title and index

        return nav.title === "Back to School" ? (
          <Link to={nav.url} key={nav.title}>
            <FlexBox
              sx={{
                py: 0.5,
                px: 1.5,
                alignItems: "center",
                border: "1px solid #5C87C5",
                borderRadius: "20px",
              }}
            >
              <BazaarImage
                src="/assets/images/icons/backtoschool.svg"
                width={24}
                height={24}
              />
              <Span
                sx={{
                  gap: 1,
                  lineHeight: 1,
                  ml: 1,
                  // fontWeight: 600,
                  alignItems: "center",
                  position: "relative",
                  paddingBottom: "1px",
                  display: "inline-flex",
                  fontWeight: "600",
                  textDecoration: isActive ? "underLine 2px #3E69A6" : "",
                  color: "#3E69A6",
                  textUnderlineOffset: "4px",
                  ":after": {
                    left: 0,
                    bottom: 0,
                    width: "0%",
                    content: "''",
                    height: "2px",
                    transition: "0.3s",
                    position: "absolute",
                  },
                }}
              >
                {/* <MenuItem sx={{ px: 0, ml: 2 }}> */}
                {nav.title}
                {/* </MenuItem> */}
              </Span>
            </FlexBox>
          </Link>
        ) : nav.title === "Наадам" ? (
          <Link to={nav.url} key={nav.title}>
            <FlexBox
              sx={{
                py: 0.5,
                px: 1.5,
                alignItems: "center",
                border: "1px solid #EC5578",
                borderRadius: "20px",
              }}
            >
              <BazaarImage
                src="/assets/images/icons/hurim.svg"
                width={24}
                height={24}
              />
              <Span
                sx={{
                  gap: 1,
                  lineHeight: 1,
                  ml: 1,
                  // fontWeight: 600,
                  alignItems: "center",
                  position: "relative",
                  paddingBottom: "1px",
                  display: "inline-flex",
                  fontWeight: "600",
                  textDecoration: isActive ? "underLine 2px #EC5578" : "",
                  color: "#EC5578",
                  textUnderlineOffset: "4px",
                  ":after": {
                    left: 0,
                    bottom: 0,
                    width: "0%",
                    content: "''",
                    height: "2px",
                    transition: "0.3s",
                    position: "absolute",
                  },
                }}
              >
                {/* <MenuItem sx={{ px: 0, ml: 2 }}> */}
                {nav.title}
                {/* </MenuItem> */}
              </Span>
            </FlexBox>
          </Link>
        ) : nav.title === "Cyber Monday" ? (
          <Link to={nav.url} key={nav.title}>
            <FlexBox
              sx={{
                py: 0.5,
                px: 1.5,
                alignItems: "center",
              }}
            >
              <Span
                sx={{
                  gap: 1,
                  lineHeight: 1,
                  alignItems: "center",
                  position: "relative",
                  paddingBottom: "4px",
                  display: "inline-flex",
                  fontWeight: "400",
                  textDecoration: isActive ? "underLine 2px #2d68c4" : "",
                  color: isActive ? "#2d68c4" : "",
                  textUnderlineOffset: "4px",
                  ":after": {
                    left: 0,
                    bottom: 0,
                    width: "0%",
                    content: "''",
                    height: "2px",
                    transition: "0.3s",
                    position: "absolute",
                  },
                  ":hover": {
                    color: "primary.main",
                    "&::after": {
                      width: "100%",
                      backgroundColor: "primary.main",
                    },
                  },
                }}
              >
                {nav.title}
              </Span>
            </FlexBox>
          </Link>
        ) : nav.title === "LOTTE" ? (
          <Link to={nav.url} key={nav.title}>
            <FlexBox
              sx={{
                py: 0.5,
                px: 1.5,
                alignItems: "center",
                border: "1px solid #E60013",
                borderRadius: "20px",
              }}
            >
              <BazaarImage
                src="/assets/images/logos/lotte_logo.svg"
                // width={24}
                // height={24}
              />
            </FlexBox>
          </Link>
        ) : nav.title === "Шинэ жил" ? (
          <Link to={nav.url} key={nav.title}>
            <FlexBox>
              <BazaarImage
                src="/assets/images/new-year/santaGif.gif"
                width={38}
                height={38}
              />
              <Span
                sx={{
                  gap: 1,
                  lineHeight: 1,
                  ml: 1,
                  alignItems: "center",
                  position: "relative",
                  paddingBottom: "1px",
                  display: "inline-flex",
                  justifyContent: "center",
                  fontWeight: "600",
                  // color: "#668412",
                  background: "linear-gradient(to right, #FF0000, #006E1F)",
                  textUnderlineOffset: "4px",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  ":after": {
                    left: 0,
                    bottom: 0,
                    width: "0%",
                    content: "''",
                    height: "2px",
                    transition: "0.3s",
                    position: "absolute",
                  },
                }}
              >
                {/* <MenuItem sx={{ px: 0, ml: 2 }}> */}
                {nav.title}
                {/* </MenuItem> */}
                <BazaarImage
                  src="/assets/images/new-year/newYearMenu.png"
                  sx={{
                    width: "110%",
                    position: "absolute",
                    bottom: "-2px",
                    pointerEvents: "none",
                  }}
                />
              </Span>
            </FlexBox>
          </Link>
        ) : (
          <Link to={nav.url} key={nav.title}>
            <Span
              sx={{
                gap: 1,
                lineHeight: 1,
                // fontWeight: 600,
                alignItems: "center",
                position: "relative",
                paddingBottom: "4px",
                display: "inline-flex",
                fontWeight: "400",
                textDecoration: isActive ? "underLine 2px #2d68c4" : "",
                color: isActive ? "#2d68c4" : "",
                textUnderlineOffset: "4px",
                ":after": {
                  left: 0,
                  bottom: 0,
                  width: "0%",
                  content: "''",
                  height: "2px",
                  transition: "0.3s",
                  position: "absolute",
                },
                ":hover": {
                  color: "primary.main",
                  "&::after": {
                    width: "100%",
                    backgroundColor: "primary.main",
                  },
                },
              }}
            >
              {/* <MenuItem sx={{ px: 0, ml: 2 }}> */}
              {nav.title}
              {/* </MenuItem> */}
            </Span>
          </Link>
        )
      }
    })
  }
  const Category = appIcons["Category"]
  const location = useLocation()
  const URL = location.pathname

  return (
    <NavBarWrapper hoverEffect={false} elevation={elevation} border={border}>
      {!hideCategories ? (
        <InnerContainer>
          {/* Category megamenu */}
          <CategoryMenu open={navListOpen}>
            <CategoryMenuButton variant="text">
              <Category fontSize="small" fontWeight="400" />
              <Paragraph
                fontWeight="600"
                textAlign="left"
                flex="1 1 0"
                ml={1.25}
                color="grey.600"
              >
                БҮХ АНГИЛАЛ
              </Paragraph>

              <ChevronRight className="dropdown-icon" fontSize="small" />
            </CategoryMenuButton>
          </CategoryMenu>

          {/* Horizontal menu */}
          <FlexBox gap={4} sx={{ fontWeight: "400", alignItems: "center" }}>
            {/* {renderNestedAuctionNav(AuctionNavbarNavigation, true, URL)} */}

            {renderNestedNav(navbarNavigations, true, URL)}
          </FlexBox>
        </InnerContainer>
      ) : (
        <InnerContainer sx={{ justifyContent: "center", fontWeight: "400" }}>
          <FlexBox gap={4} sx={{ alignItems: "center" }}>
            {/* {renderNestedAuctionNav(AuctionNavbarNavigation, true, URL)} */}

            {renderNestedNav(navbarNavigations, true, URL)}
          </FlexBox>
        </InnerContainer>
      )}
    </NavBarWrapper>
  )
}

//  set default props data
Navbar.defaultProps = {
  elevation: 2,
  navListOpen: false,
  hideCategories: false,
}

export default Navbar
