import { ArrowRight } from "@mui/icons-material"
import { Link } from "@remix-run/react"
import React, { ReactNode } from "react"
import { FlexBetween, FlexBox } from "./flex-box"
import { H3 } from "./Typography"

// ===================================================
type CategorySectionHeaderProps = {
  title?: string
  icon?: ReactNode
  seeMoreLink?: string
}
// ===================================================

const CategorySectionHeader: React.FC<CategorySectionHeaderProps> = (props) => {
  const { title, seeMoreLink, icon } = props

  return (
    <FlexBetween mb={1.5}>
      <FlexBox alignItems="center" gap={1}>
        {icon && <FlexBox alignItems="center">{icon}</FlexBox>}
        <H3
          fontWeight="bold"
          lineHeight="1"
          fontFamily={"Rubik"}
          sx={{ fontSize: { md: "24px", xs: "20px" } }}
        >
          {title}
        </H3>
      </FlexBox>

      {seeMoreLink && (
        <Link to={seeMoreLink}>
          <FlexBox
            alignItems="center"
            color="grey.600"
            // color="white"
            sx={{
              fontSize: { xs: "14px", md: "18px" },
              fontWeight: { xs: "400", md: "600" },
            }}
          >
            Бүгд
            <ArrowRight fontSize="small" color="inherit" />
          </FlexBox>
        </Link>
      )}
    </FlexBetween>
  )
}

export default CategorySectionHeader
