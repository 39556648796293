import { Box, styled } from "@mui/material"
import { Link } from "@remix-run/react"
import { FC, useEffect, useState } from "react"
import BazaarCard from "~/components/BazaarCard"
import BazaarImage from "~/components/BazaarImage"
import useWindowSize from "~/hooks/useWindowSize"

// styled component
const StyledBazaarCard = styled(BazaarCard)(({ theme }) => ({
  height: "100%",
  display: "flex",
  backgroundColor: "#F6F9FC",
  borderRadius: "18px",
  border: "1px solid rgba(0,0,0,0.5)",
  // boxShadow: "3px 3px 6px 3px #bbbbbb",
  alignItems: "center",
  justifyContent: "center",
}))

// ===========================================================

// ===========================================================

const MobileNavbarMenu: FC = ({}) => {
  const width = useWindowSize()
  const [windowWidth, setWindowWidth] = useState(false)
  useEffect(() => {
    window.innerWidth <= 1024 ? setWindowWidth(true) : setWindowWidth(false)
  }, [])
  return windowWidth ? (
    // <Container sx={{ mb: 2 }}>
    //   <Box>
    //     <Grid container spacing={2} my={2}>
    //       {categories.map((item, ind) => (
    //         <Grid item xs={6} key={ind}>
    //           <Link to={item.url}>
    //             <StyledBazaarCard>
    //               <Box sx={{ py: 1, fontSize: "11px", display:"flex", }}>
    //               <BazaarImage width={18} marginRight={"5px"} src={item.icon}></BazaarImage>
    //                 {item.title}</Box>
    //             </StyledBazaarCard>
    //           </Link>
    //         </Grid>

    //       ))}
    //     </Grid>

    //   </Box>

    // </Container>
    <div style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}>
      <Box
        className="scrollPhone"
        component="div"
        sx={{
          whiteSpace: "nowrap",
          overflowX: "scroll",
          my: 1,
          p: 1,
          borderRadius: 2,
          fontWeight: "600",
          display: "flex",
        }}
      >
        {categories.map((item, ind) =>
          item.title === "Шинэ жил" ? (
            <Box key={ind}>
              <Link to={item.url}>
                <BazaarCard
                  sx={{
                    marginRight: "10px",
                    px: "10px",
                    height: "100%",
                    display: "flex",
                    backgroundColor: "#F6F9FC",
                    border: "solid 1px transparent",
                    borderRadius: "20px",
                    backgroundImage:
                      "linear-gradient(white, white), linear-gradient(to right, #FF0000, #006E1F)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "padding-box, border-box",
                    // boxShadow: "3px 3px 6px 3px #bbbbbb",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      py: 1,
                      fontSize: "11px",
                      display: "flex",
                      background: "linear-gradient(to right, #FF0000, #006E1F)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    <BazaarImage
                      width={18}
                      marginRight={"5px"}
                      src={item.icon}
                    ></BazaarImage>

                    {item.title}
                  </Box>
                </BazaarCard>
              </Link>
            </Box>
          ) : item.title === "LOTTE" ? (
            <Box key={ind}>
              <Link to={item.url}>
                <BazaarCard
                  sx={{
                    marginRight: "10px",
                    px: "10px",
                    height: "100%",
                    display: "flex",
                    backgroundColor: "#F6F9FC",
                    borderRadius: "18px",
                    border: "1px solid #E60013",
                    // boxShadow: "3px 3px 6px 3px #bbbbbb",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      py: 0.5,
                      fontSize: "11px",
                      display: "flex",
                      background:
                        "linear-gradient(to right, #F6730B, #EA3956, #8C1CAF, #2369DB)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    <BazaarImage src={item.icon} height={18}></BazaarImage>
                  </Box>
                </BazaarCard>
              </Link>
            </Box>
          ) : (
            <Box key={ind}>
              <Link to={item.url}>
                <BazaarCard
                  sx={{
                    marginRight: "10px",
                    px: "10px",
                    height: "100%",
                    display: "flex",
                    backgroundColor: "#F6F9FC",
                    borderRadius: "18px",
                    border:
                      item.title === "Love Story"
                        ? "1px solid #EC5578"
                        : item.title === "Дуудлага худалдаа"
                        ? "1px solid #10A4B0"
                        : item.title === "Back to School"
                        ? "1px solid #5C87C5"
                        : "1px solid rgba(0,0,0,0.5)",
                    // boxShadow: "3px 3px 6px 3px #bbbbbb",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      py: 1,
                      fontSize: "11px",
                      display: "flex",
                      color:
                        item.title === "Love Story"
                          ? "#EC5578"
                          : item.title === "Back to School"
                          ? "#3E69A6"
                          : item.title === "Дуудлага худалдаа"
                          ? "#10A4B0"
                          : "",
                    }}
                  >
                    <BazaarImage
                      width={18}
                      marginRight={"5px"}
                      src={item.icon}
                    ></BazaarImage>
                    {item.title}
                  </Box>
                </BazaarCard>
              </Link>
            </Box>
          )
        )}
      </Box>
    </div>
  ) : null
}

export default MobileNavbarMenu

const categories = [
  // {
  //   icon: "/assets/images/icons/zuslan.svg",
  //   title: "Зуслан",
  //   url: "/zuslan",
  // },
  // {
  //   icon: "/assets/images/icons/hurim.svg",
  //   title: "Love Story",
  //   url: "/t/34458",
  // },
  // {
  //   icon: "/assets/images/icons/backtoschool.svg",
  //   title: "Back to School",
  //   url: "/t/19469",
  // },
  // {
  //   icon: "/assets/images/auction/bidding.svg",
  //   title: "Дуудлага худалдаа",
  //   url: "/auctions",
  // },
  // {
  //   icon: "/assets/images/new-year/santaGif.gif",
  //   title: "Шинэ жил",
  //   url: "/new-year",
  // },
  // {
  //   icon: "/assets/images/logos/lotte_logo.svg",
  //   title: "LOTTE",
  //   url: "/t/34285",
  // },
  {
    icon: "/assets/images/PhoneNavbarIcons/discount-svgrepo-com.svg",
    title: "Хямдрал",
    url: "/t/27318",
  },
  {
    icon: "/assets/images/PhoneNavbarIcons/beleg-icon.svg",
    title: "Бэлэгтэй",
    url: "/t/29716",
  },
  {
    icon: "/assets/images/PhoneNavbarIcons/Category.svg",
    title: "Брэнд",
    url: "/brands",
  },
  {
    icon: "/assets/images/PhoneNavbarIcons/baiguullaga.svg",
    title: "Байгууллагад",
    url: "/b",
  },
  // {
  //   icon: "/assets/images/PhoneNavbarIcons/ontsloh.svg",
  //   title: "Онцлох",
  //   url: "/featured",
  // },
]
